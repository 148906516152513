import React, { Component } from 'react';
import './computed/App.css';
import TypedReact from './js/Typed.js';
import Trend from 'react-trend';

class Timer extends Component {

  constructor(props) {
    super(props);

    console.log("constructor ::");
    this.state = {
      secondsElapsed: 0
    }
  }

  tick = () => {
    this.setState({ secondsElapsed: this.state.secondsElapsed + 1});
  }

  componentDidMount= () => {
    console.log("componentDidMount ::");
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>Seconds Elapsed: {this.state.secondsElapsed}</div>
    );
  }
};


class HomeApp extends Component {

  constructor(props) {
    super(props);

    //console.log("Home APP constructor ::");
    this.state = {
      secondsElapsed: 0,
      data : [0,2,5,9,5,10,3,5,0,0,1,8,2,9,0,10,3,5,0,0,1,8,2,9,0]
    }
  }

  tick = () => {
    this.setState({ secondsElapsed: this.state.secondsElapsed + 1});
    this.setState({ data : [] });
    this.setState({ data : [4,0,9,1,5,10,3,5,0,0,9,0,0,1,0,10,3,5,0,0,1,8,2,9,0] });
  }

  componentDidMount= () => {
    //console.log("componentDidMount ::");
    this.interval = setInterval(this.tick, 6000);
  }

  render() {
    return (
      <div className="App">
          {/* <Trend
              smooth
              autoDraw
              autoDrawDuration={3000}
              autoDrawEasing="ease-out"
              data={this.state.data}
              gradient={['RGBA(130, 181, 65, .2)', 'RGBA(255, 255, 255, .10)', 'RGBA(35, 86, 112, .20)']}
              radius={13.2}
              strokeWidth={1.2}
              strokeLinecap={'square'}
            /> */}
          {/* <Timer/> */}
        {/* <TypedReact
          strings={[
            'Some <i>strings</i> are slanted',
            'Some <strong>strings</strong> are bold',
            'HTML characters &times; &copy;'
          ]}
         /> */}


      </div>
    );
  }
}

export default HomeApp;
