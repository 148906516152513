import React from 'react';
import ReactDOM from 'react-dom';
//import TextareaAutosize from 'react-autosize-textarea';
import $ from 'jquery';
import './computed/sass/form.css';

// const reactFormContainer = document.querySelector('.react-form-container');

class ReactFormLabel extends React.Component {
  constructor() {
    super();
  }
  
  render() {
    return(
      <label htmlFor={this.props.htmlFor}>{this.props.title}</label>
    )
  }
}

class ReactFormThanks extends React.Component {
  constructor() {
    super();
  }
  render() {
        if(this.props.submitted){
            return(<h1>Thank You! {this.props.submitted} </h1>)
        } else {
            return(null)
        }
        
        }
}


class ReactForm extends React.Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      submitted: false,
      errors: {}
    }
  }
  
  handleChange = (e) => {
    let newState = {};

    let errors = {};

    if (e.target.name == 'name' && e.target.value < 1 ) {
      errors.Name = 'required';
    }
    else{
      errors.Name = '';
    }

    this.setState({errors : errors});

    // if (formData.Email.length < 1) {
    //   errors.email = 'Required';
    //   return false;
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.Email)) {
    //   errors.email = 'Invalid email address';
    //   return false;
    // }

    newState[e.target.name] = e.target.value;

    this.setState(newState);
  };
  
  handleSubmit = (e, message) => {
    e.preventDefault();

    let formData = {
      Name: this.state.name,
      Email: this.state.email,
      //Subject: 'website email sapphireanalytics.io',
      Subject: this.state.subject,
      Message: this.state.message.replace(/\r?\n/g, '<br />'),
      source: 'sapphire-analytics-website'
    }

    

    this.setState({ submitted: false});
    
    
    if (formData.Name.length < 1 || formData.Email.length < 1 || formData.Message.length < 1) {
      return false;
    }

    $.ajax({
      url: 'https://us-central1-sapphireanalytics-website.cloudfunctions.net/sendmail',
      dataType: 'json',
      type: 'POST',
      data: formData,
      success: function(data) {
        // if (confirm('Thank you for your message. Can I erase the form?')) {
        //   document.querySelector('.form-input').val('');
        // }
        //this.setState({ submitted: true});
      },
      error: function(xhr, status, err) {
        //this.setState({ submitted: true});
        console.error(status, err.toString());
        console.log('There was some problem with sending your message.');
      }
    });
    
    this.setState({ submitted: true});

    // this.setState({
    //   name: '',
    //   email: '',
    //   subject: '',
    //   message: '',
    //   submitted: false
    // });
  };

  render() {
    return(
     <div>
      <form className='react-form' onSubmit={this.handleSubmit}>
        <h1>Contact Us</h1>
        <h4>Let’s talk shop. Analytics is moving so quickly that we all rely on the community to stay current.</h4>
        <ReactFormThanks submitted={this.state.submitted}/>
       
        <div className={ this.state.submitted ? 'hide-form' : '' }>
              <fieldset className='form-group'>
                <ReactFormLabel htmlFor='formName' title='Full Name:' />

                <input id='formName' className={ this.state.errors.Name == "required" ? "form-input required" : "form-input" } name='name' type='text' required ref='formName' onChange={this.handleChange} value={this.state.name} />
              </fieldset>
              
              <fieldset className='form-group'>
                <ReactFormLabel htmlFor='Email' title='Email:' />

                <input id='Email' className='form-input' name='email' type='email' required onChange={this.handleChange} value={this.state.email} />
              </fieldset>
                 
              <fieldset className='form-group'>
                <ReactFormLabel htmlFor='Subject' title='Subject:'/>

                <input id='Subject' className='form-input' name='subject' type='text' required onChange={this.handleChange} value={this.state.subject} />
              </fieldset> 
              
              <fieldset className='form-group'>
                <ReactFormLabel htmlFor='Message' title='Message:' />
                <textarea id='Message' className='form-textarea' name='message' required onChange={this.handleChange} rows={6} />
                {/* <textarea id='Message' className='form-textarea' name='message' required onChange={this.handleChange}></textarea> */}
              </fieldset>
              
              <div className='form-group'>
                <input id='formButton' className='btn' type='submit' placeholder='Send message' />
              </div>
        </div>
      </form>
    </div>
    )
  }
};

export default ReactForm;

//ReactDOM.render(<ReactForm />, reactFormContainer);