import React, { Component } from 'react';
import logo from './logo.svg';
import './computed/App.css';
import ReactForm from './ReactForm';
import TypedReact from './js/Typed.js';

class App extends Component {
  render() {
    return (
      <div className="App">
        <ReactForm />
        
        {/* <TypedReact
          strings={[
            'Some <i>strings</i> are slanted',
            'Some <strong>strings</strong> are bold',
            'HTML characters &times; &copy;'
          ]}
         /> */}


      </div>
    );
  }
}

export default App;
