import React from 'react';
import ReactDOM from 'react-dom';
import './computed/index.css';
import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';
import App from './App';
import HomeApp from './HomeApp';
import registerServiceWorker from './registerServiceWorker';
import './js/scroll.js';


import Typed from 'typed.js';

    var options = {
    strings: [ "We help <strong>Entrepreneurs forecast sales</strong>",
    "We help <strong>Entrepreneurs forecast sales</strong>", 
        "We help <strong>Marketers measure smarter</strong>",
        "We help <strong>eCommerce sites with channel analytics</strong>" ],
    smartBackspace: true, // Default value
    typeSpeed: 90,
    showCursor: true,
    cursorChar: '|',
    autoInsertCss: true,
    loop: true,
    backDelay: 1500,
    contentType: 'html'
                }

//https://jsfiddle.net/mattboldt/ovat9jmp/
//eslint-disable-next-line

// eslint-disable-next-line
var typed = new Typed(".typedelement", options);


ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<HomeApp />, document.getElementById('home-react'));

registerServiceWorker();
